import { template as template_3fcd22c1465040b9a81240ab25f3f116 } from "@ember/template-compiler";
const FKLabel = template_3fcd22c1465040b9a81240ab25f3f116(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
