import { template as template_53f992dae67444a6a44fda47753cc75b } from "@ember/template-compiler";
const FKControlMenuContainer = template_53f992dae67444a6a44fda47753cc75b(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
